import { enqueueSnackbar } from "notistack"
import config from "../config"
import { getItems } from "./store"
import _ from "lodash"

export const editItem = async (project, itemData, setDisableBtn, publicKey, projectId, setItems, setDisplayModal, setDisplaySignModal) => {
    try {

        if (!publicKey) return enqueueSnackbar({ message: 'You need to connect your wallet', variant: 'error' })
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        let itemDataFormatted = { ...itemData }

        if (itemDataFormatted.data?.itemFundsWallets?.length) {
            let itemFundsWallet = itemDataFormatted.data?.itemFundsWallets?.find(z => z?.walletAddress === project.data?.storeFundsWallet)
            _.set(itemDataFormatted, 'data.itemFundsWallets', [itemDataFormatted.data?.itemFundsWallets[0]])
            if (itemFundsWallet && !itemDataFormatted.data?.projectShare) _.set(itemDataFormatted, 'data.projectShare', itemFundsWallet?.percent)
        }
        const resInfo = await fetch(`${config.apiURL}/store-edit-item`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ itemData: itemDataFormatted, nonceId, nonceSignature, walletAddress: publicKey, projectId })
        })
        const res = await resInfo.json()
        if (!res.status) {
            if (res.data.nonce) {
                setDisplayModal(false)
                return setDisplaySignModal(true)
            }
        }
        await getItems(setItems)

        setDisableBtn(false)
        enqueueSnackbar({ message: res.message, variant: res.status ? 'success' : 'error' })
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || 'UNKNOWN'}`, variant: 'error' })
        console.log(err)
    }
}

export const deleteItem = async (itemId, setDisableBtn, publicKey, projectId, setItems, setDisplayModal, setDisplaySignModal) => {
    try {
        if (!publicKey) return enqueueSnackbar({ message: 'You need to connect your wallet', variant: 'error' })
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const resInfo = await fetch(`${config.apiURL}/store-delete-item`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                itemId,
                walletAddress: publicKey,
                projectId: projectId,
                nonceId,
                nonceSignature
            })
        })
        const res = await resInfo.json()
        if (!res.status) {
            if (res.data.nonce) {
                setDisplayModal(false)
                return setDisplaySignModal(true)
            }
        }
        await getItems(setItems)
        setDisableBtn(false)
        setDisplayModal(false)
        enqueueSnackbar({ message: res.message, variant: res.status ? 'success' : 'error' })
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || 'UNKNOWN'}`, variant: 'error' })
        console.log(err)
    }
}



export const createItem = async (project, itemData, setDisableBtn, publicKey, projectId, setItems, setDisplayModal, setDisplaySignModal) => {
    try {
        if (!publicKey) return enqueueSnackbar({ message: 'You need to connect your wallet', variant: 'error' })
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        let itemDataFormatted = { ...itemData }
        let itemFundsWallet = itemDataFormatted.data?.itemFundsWallets?.find(z => z?.walletAddress === project.data?.storeFundsWallet)
        if (itemFundsWallet && !itemDataFormatted.data?.projectShare) _.set(itemDataFormatted, 'data.projectShare', itemFundsWallet?.percent)

        const resInfo = await fetch(`${config.apiURL}/store-create-item`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ itemData, nonceId, nonceSignature, walletAddress: publicKey, projectId })
        })
        const res = await resInfo.json()
        if (!res.status) {
            if (res.data.nonce) {
                setDisplayModal(false)
                return setDisplaySignModal(true)
            }
        }
        await getItems(setItems)
        setDisableBtn(false)
        enqueueSnackbar({ message: res.message, variant: res.status ? 'success' : 'error' })
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || 'UNKNOWN'}`, variant: 'error' })
        console.log(err)
    }
}
