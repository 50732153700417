import { useWallet } from "@solana/wallet-adapter-react"
import { useContext, useEffect, useState } from "react"
import { deleteItem, editItem } from "../../javascript/dashboard"
import { StoreContext } from "../../App"
import ItemFormInputs from "./ItemFormInputs"


const EditItemForm = ({ displayModal, setDisplayModal, activeEditingItem: itemData, setActiveEditingItem: setItemData }) => {
    const [disableBtn, setDisableBtn] = useState(false)
    const { publicKey } = useWallet()
    const { project, setItems, signModalState } = useContext(StoreContext)
    const [, setDisplaySignModal] = signModalState


    return itemData && displayModal ? (
        <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
            <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
            <div className='modal-container'>
                <div className='modal'>
                    <form className='utility__form font-inter'>
                        <div className='heading-text font-strange' style={{ margin: '2rem 0px' }}>
                            Edit Item
                        </div>

                        <ItemFormInputs itemData={itemData} setItemData={setItemData} />

                        <div className='field half'>
                            <button className='button' disabled={disableBtn} onClick={() => deleteItem(itemData.itemId, setDisableBtn, publicKey, project.id, setItems, setDisplayModal, setDisplaySignModal)}>Delete</button>
                        </div>

                        <div className='field half'>
                            <button className='button green-button' disabled={disableBtn} onClick={() => editItem(project, itemData, setDisableBtn, publicKey, project.id, setItems, setDisplayModal, setDisplaySignModal)}>Save</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    ) : (<></>)

}

export default EditItemForm