import React, { useContext } from 'react'
import Navbar from '../utility/Navbar'
import { CheckOutFormContext } from '../../pages/Cart'
import { StoreContext } from '../../App'
import CartItemsTable from './CartItemsTable'
import { Box, Button, MenuItem, Select } from '@mui/material'
import { Textarea } from '@mui/joy'
import { Link } from 'react-router-dom'

export default function CartPage() {
    const {orderSpecialInstruction, setOrderSpecialInstruction, cart,
        tokenOption, setTokenOption
    } = useContext(CheckOutFormContext)
    const { items, project, prices } = useContext(StoreContext)

    return (
        <div>
            {!cart.length ? (
                <>
                    <Navbar />
                    <div className='container-padding centered-text'>
                        No item in your cart
                    </div>

                </>
            ) : (
                <>
                    <Navbar />
                    <div className='container-padding'>
                        <div className='heading-text font-strange' style={{ margin: '2rem 0px 1rem 0px' }}>
                            YOUR CART
                        </div>

                        <CartItemsTable tokenOption={tokenOption} cart={cart} />
                        <div className='flex flex-row flex-wrap flex-justify-between' style={{ padding: '1rem', color: 'white' }}>
                            <div>
                                <div>
                                    ORDER SPECIAL INSTRUCTIONS
                                </div>
                                <Box sx={{
                                    margin: '1rem 0px',
                                    '& .MuiTextarea-root': {
                                        background: '#262626',
                                        borderRadius: '10px',
                                        width: '300px',
                                        color: 'white',
                                        borderColor: 'transparent',
                                        maxWidth: '80vw',
                                        minHeight: '100px',
                                    },
                                    '&:hover .MuiTextarea-root': {
                                        '& textarea': {
                                            color: 'white',
                                            borderColor: 'transparent',
                                        }
                                    },
                                    '& .MuiTextarea-root.Joy-focused': {
                                        '--Textarea-focusedHighlight': 'transparent',
                                        background: '#353535'
                                    }
                                }}>
                                    <Textarea value={orderSpecialInstruction} onChange={(event) => {
                                        setOrderSpecialInstruction(event.target.value)
                                    }} />
                                </Box>
                            </div>
                            <div>

                                <div className='flex flex-row flex-wrap flex-justify-center' style={{ gap: '1rem' }}>
                                    <div>SUBTOTAL</div><div className='font-inter'>
                                        {parseFloat((cart.map(x => {
                                            return {
                                                ...items.find(item => item.itemId === x.itemId),
                                                ...x
                                            }
                                        }).reduce((a, x) => a + (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption) ?
                                            (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice ?
                                                parseFloat(((x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice) / (prices[tokenOption] || project.data.storeOptions.find(z => z.tokenAddress === tokenOption).fixedUSDPrice) * x.quantity).toFixed(3)) :
                                                x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).price * x.quantity) :
                                            (x.priceOptions[0].usdPrice ?
                                                parseFloat(((x.priceOptions[0].usdPrice) / prices[x.priceOptions[0].data.tokenAddress] * x.quantity).toFixed(3)) :
                                                x.priceOptions[0].price * x.quantity)), 0)
                                        ).toFixed(10))}
                                        <Select
                                            value={tokenOption}
                                            style={{ marginLeft: '0.5rem' }}
                                            sx={{
                                                '& .MuiSelect-select': {
                                                    padding: '0px',
                                                    color: 'white',
                                                    fontFamily: 'Inter !important'
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent !important',
                                                }
                                            }}
                                            onChange={(event) => setTokenOption(event.target.value)}
                                        >
                                            {project.data.storeOptions.filter(z => !cart.map(x => items.find(item => item.itemId === x.itemId)).find(y => !y.priceOptions.find(y => y.globalOptionId === z.globalOptionId))).map((x, i) => {
                                                return (
                                                    <MenuItem key={i} value={x.tokenAddress}>{x.symbol}</MenuItem>
                                                )
                                            })}
                                        </Select>

                                    </div>
                                </div>
                                <Link to={'checkout'}>
                                    <Button className='button__red' style={{ maxWidth: '400px', marginTop: '1rem' }}>CHECK OUT</Button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </div >)
}
