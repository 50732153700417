import React, { createContext, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../App';
import { Route, Routes } from 'react-router-dom';
import CheckOutPage from '../components/cart/CheckOutPage';
import CartPage from '../components/cart/CartPage';

export const CheckOutFormContext = createContext({
    orderSpecialInstruction: undefined,
    setOrderSpecialInstruction: undefined,
    email: undefined,
    setEmail: undefined,
    country: undefined,
    setCountry: undefined,
    firstName: undefined,
    setFirstName: undefined,
    lastName: undefined,
    setLastName: undefined,
    address: undefined,
    setAddress: undefined,
    city: undefined,
    setCity: undefined,
    state: undefined,
    setState: undefined,
    zip: undefined,
    setZip: undefined,
    tokenOption: undefined,
    setTokenOption: undefined,
    cart: undefined,
    domestic: undefined,
    setDomestic: undefined
})
export default function Cart() {
    const { project, items } = useContext(StoreContext)
    const [cart, setCart] = useState((JSON.parse(localStorage.getItem('cart_items'))) || [])
    const [domestic, setDomestic] = useState(true)
    const [orderSpecialInstruction, setOrderSpecialInstruction] = useState('')

    const [checkOutPage, setCheckOutPage] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')

    const [country, setCountry] = useState('')

    const [tokenOption, setTokenOption] = useState(project.data.storeOptions[0].tokenAddress)

    useEffect(() => {
        if (cart?.length) {
            setTokenOption(project.data.storeOptions.filter(z => !cart.map(x => items.find(item => item.itemId === x.itemId)).find(y => !y.priceOptions.find(y => y.globalOptionId === z.globalOptionId)))[0]?.tokenAddress)
        }
    }, [cart, items, project.data.storeOptions])
    useEffect(() => {
        const changeStorage = (change) => {
            setCart(JSON.parse(localStorage.getItem('cart_items')) || [])
        };

        window.addEventListener('cartItemsChange', changeStorage);
        window.addEventListener('storage', changeStorage);
        return () => {
            window.removeEventListener('storage', changeStorage);
            window.removeEventListener('cartItemsChange', changeStorage);
        };
    }, []);

    return (
        <CheckOutFormContext.Provider value={{
            orderSpecialInstruction,
            setOrderSpecialInstruction,
            email,
            setEmail,
            country,
            setCountry,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            address,
            setAddress,
            city,
            setCity,
            state,
            setState,
            zip,
            setZip,
            tokenOption,
            setTokenOption,
            cart,
            domestic, 
            setDomestic
        }}>
            <Routes>
                <Route path='/checkout' element={(<CheckOutPage cart={cart} checkOutPage={checkOutPage} setCheckOutPage={setCheckOutPage} tokenOption={tokenOption} />)} />
                <Route path='/' element={(<CartPage />)} />
            </Routes>
        </CheckOutFormContext.Provider>

    )
}



