import { Box, Button, Checkbox, FormControlLabel, Input } from "@mui/material"
import { useContext, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useCheckMobileScreen } from "../../javascript/utility"
import { StoreContext } from "../../App"
import { CheckOutFormContext } from "../../pages/Cart"
import { placeOrder } from "../../javascript/store"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { IoIosArrowForward } from 'react-icons/io'
const CheckOutPage = ({ checkOutPage, setCheckOutPage, cart, tokenOption }) => {
    const { items, project, prices } = useContext(StoreContext)
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    const [disableBtn, setDisableBtn] = useState(false)
    const { orderSpecialInstruction,
        email,
        setEmail,
        country,
        setCountry,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        address,
        setAddress,
        city,
        setCity,
        state,
        setState,
        zip,
        setZip,
        domestic,
        setDomestic
    } = useContext(CheckOutFormContext)
    const isMobile = useCheckMobileScreen()
    return (
        <div className={`flex flex-row ${isMobile ? 'flex-wrap' : ''} flex-justify-center`} style={{ margin: 'auto', minHeight: '100vh', gap: '2rem' }}>
            <div className='container-left-padding' style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexFlow: 'row', justifyContent: !isMobile ? 'flex-end' : 'center' }}>
                <div className='flex flex-column flex-align-center' style={{ width: '500px', maxWidth: isMobile ? '90vw' : '40vw', padding: '1.3rem 0px' }}>
                    <img
                        src="https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407788beffc5b04ecfed655_SD_LOGO2.png"
                        alt=""
                        style={{ height: '4rem' }}
                        className="navbar-logo"
                    />
                    <Box sx={{
                        color: 'white',
                        width: '100%',
                        display: 'flex',
                        flexFlow: 'column',
                        margin: '2rem 0px',
                        gap: '0.5rem',
                        '& .MuiInputBase-root': {
                            background: 'white',
                            width: '100%',
                            borderRadius: '5px'
                        },
                        '& .MuiInputBase-input': {
                            paddingLeft: '1rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem'
                        },
                        '& .button__red': {
                            fontFamily: 'Inter !important',
                            marginLeft: 'auto',
                            marginTop: '1rem',
                            width: 'fit-content'
                        }
                    }}>
                        <div className='font-inter' style={{ fontWeight: '600' }}>
                            Contact
                        </div>
                        <Input type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />

                        <div className='font-inter' style={{ fontWeight: '600', marginTop: '1rem' }}>
                            Shipping address
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{ color: 'white' }}
                                    checked={domestic}
                                    onChange={(event) => setDomestic(event.target.checked)}
                                    color="default"
                                />
                            }
                            label="Are you in USA?"
                        />
                        <Input placeholder="Country" value={address} onChange={(event) => setAddress(event.target.value)} />
                        <div className='flex flex-row' style={{ gap: '0.5rem' }}>
                            <Input placeholder="First name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                            <Input placeholder="Last name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                        </div>
                        <Input placeholder="Address" value={country} onChange={(event) => setCountry(event.target.value)} />
                        <div className='flex flex-row' style={{ gap: '0.5rem' }}>
                            <Input required placeholder="City" value={city} onChange={(event) => setCity(event.target.value)} />
                            <Input required placeholder="State" value={state} onChange={(event) => setState(event.target.value)} />
                            <Input required placeholder="Zip Code" value={zip} onChange={(event) => setZip(event.target.value)} />
                        </div>
                        {publicKey ? (<Button className='button__red' disabled={disableBtn} onClick={() => {
                            placeOrder(publicKey, project,
                                { firstName, secondName: lastName, address: { address: `${address}, ${city}, ${state}`, zip: zip, country, orderSpecialInstruction }, email, domestic },
                                project.data.storeOptions.find(z => z.tokenAddress === tokenOption), cart, setDisableBtn, connection, signTransaction, setCheckOutPage, true)
                        }}>PAY NOW</Button>) : (
                            <WalletMultiButton className='button__red' />
                        )}
                    </Box>
                </div>
            </div>
            <div className='container-right-padding' style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexFlow: 'row', justifyContent: !isMobile ? 'flex-start' : 'center', background: '#322F2F', paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className='flex flex-column flex-align-center' style={{ width: '500px', maxWidth: isMobile ? '90vw' : '40vw', gap: '0.5rem' }}>
                    {cart.map(x => {
                        return {
                            ...items.find(item => item.itemId === x.itemId),
                            ...x
                        }
                    }).map((x, i) => (
                        <div key={i} className='flex flex-row flex-justify-between flex-align-center font-inter' style={{ color: 'white', gap: '0.5rem', width: '90%', fontSize: '0.8rem' }}>
                            <div className='flex flex-row flex-wrap flex-justify-center flex-align-center' style={{ gap: '0.5rem' }}>
                                <div className='item-container' style={{ width: '70px', maxWidth: '50vw', position: 'relative', overflow: 'visible' }}>
                                    <div className='font-inter cart__checkout__item__quantity'>
                                        {x.quantity}
                                    </div>
                                    <div className="item_container__img_container">
                                        <LazyLoadImage style={{ borderRadius: '10px' }} src={x.image} alt={x.label} />
                                    </div>
                                </div>
                                <div className='font-inter' >
                                    {x.label}
                                </div>
                            </div>
                            <div>
                                {
                                    x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption) ?
                                        (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice ?
                                            `${parseFloat(((x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice) / (prices[tokenOption] || project.data.storeOptions.find(z => z.tokenAddress === tokenOption).fixedUSDPrice)).toFixed(3))} ${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).data.symbol}` :
                                            `${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).price} ${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).data.symbol}`) :
                                        (x.priceOptions[0].usdPrice ?
                                            `${parseFloat(((x.priceOptions[0].usdPrice) / prices[x.priceOptions[0].data.tokenAddress]).toFixed(3))} ${x.priceOptions[0].symbol}` :
                                            `${x.priceOptions[0].price} ${x.priceOptions[0].symbol}`)
                                }

                            </div>
                        </div>
                    ))}

                    <div className='flex flex-row flex-wrap flex-justify-between flex-align-center font-inter' style={{ color: 'white', width: '90%', fontSize: '1rem', marginTop: '1rem', fontWeight: '600' }}>
                        <div>
                            Total
                        </div>
                        <div>
                            {parseFloat((cart.map(x => {
                                return {
                                    ...items.find(item => item.itemId === x.itemId),
                                    ...x
                                }
                            }).reduce((a, x) => a + (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption) ?
                                (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice ?
                                    parseFloat(((x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice) / (prices[tokenOption] || project.data.storeOptions.find(z => z.tokenAddress === tokenOption).fixedUSDPrice) * x.quantity).toFixed(3)) :
                                    x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).price * x.quantity) :
                                (x.priceOptions[0].usdPrice ?
                                    parseFloat(((x.priceOptions[0].usdPrice) / prices[x.priceOptions[0].data.tokenAddress] * x.quantity).toFixed(3)) :
                                    x.priceOptions[0].price * x.quantity)), 0)
                            ).toFixed(10))} {project.data.storeOptions.find(z => (tokenOption === z.tokenAddress)).symbol}
                        </div>
                    </div>
                    <div className='flex flex-row flex-wrap flex-justify-between flex-align-center font-inter' style={{ color: 'white', width: '90%', fontSize: '1rem', marginTop: '1rem', fontWeight: '600' }}>
                        <div>
                            Shipping
                        </div>
                        <div>
                            {domestic ? 5 : 20} USD
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CheckOutPage