import React, { useContext, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { StoreContext } from '../App'
import { Button, Grid } from '@mui/material'
import Navbar from '../components/utility/Navbar'
import EditItemForm from '../components/dashboard/EditItemForm'
import CreateItemForm from '../components/dashboard/CreateItemForm'
import {  WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'

export default function Dashboard() {
    const { items, project } = useContext(StoreContext)
    const [editFormModal, setEditFormModal] = useState(false)
    const { publicKey } = useWallet()
    const [createItemFormModal, setCreateItemFormModal] = useState(false)
    const [activeEditingItem, setActiveEditingItem] = useState()
    return (
        <>
            <Navbar />
            {project.data?.storeDashboardWallets?.includes(publicKey?.toString()) ? (<><EditItemForm setDisplayModal={setEditFormModal} displayModal={editFormModal} activeEditingItem={activeEditingItem} setActiveEditingItem={setActiveEditingItem} />
                <CreateItemForm setDisplayModal={setCreateItemFormModal} displayModal={createItemFormModal} />
                <div className='container-padding'>
                    <div className='home__category__items__container' style={{ justifyContent: 'center' }}>
                        <div style={{ justifyContent: 'flex-end', gap: '0.5rem', width: '100%' }} className='flex flex-row flex-wrap'>

                            <Button className='button__red' style={{ marginLeft: 'auto' }} onClick={() => setCreateItemFormModal(true)}>
                                CREATE ITEM
                            </Button>

                            <WalletMultiButton className='button__red' />
                        </div>

                        {!items.length ? (<div style={{ color: 'white', fontSize: '2rem', textAlign: 'center' }}>
                            No Item found
                        </div>) : (<Grid container spacing={{ xs: 2, md: 2, lg: 4 }} columns={{ xs: 4, sm: 12, md: 12, lg: 6 }}>

                            {items.map((z, j) => {
                                return (
                                    <Grid item xs={2} sm={3} md={3} lg={1} key={j}>
                                        <div className='home__category__item item-container catalog__category__item' onClick={() => {
                                            setEditFormModal(true)
                                            setActiveEditingItem(z)
                                        }}>
                                            <div className='item_container__img_container'>
                                                <LazyLoadImage src={z.image} alt={z.label} />
                                            </div>
                                            <div className='home__category__item__content-text'>
                                                <div style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    width: '80%',
                                                    margin: 'auto'
                                                }}>
                                                    {z.label}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>)
                        }
                    </div>
                </div ></>) : (<>{!publicKey ? <WalletMultiButton className='button__red' /> : <WalletDisconnectButton className='button__red' />}</>)}
        </>
    )
}
