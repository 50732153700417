import { enqueueSnackbar } from 'notistack'
import React, { useContext, useEffect } from 'react'
import { getTokenInfo } from '../../javascript/utility'
import AddPriceOptionInput from './AddPriceOptionInput'
import ArrayInput from '../utility/ArrayInput'
import { useConnection } from '@solana/wallet-adapter-react'
import _ from 'lodash'
import { StoreContext } from '../../App'


export const merchItemTemplate = {
    "image": "",
    "backImage": "",
    "sizeOptions": [],
    "description": "",
    "label": "",
    "categories": [],
    "defaultColor": "",
    "colorOptions": [],
    "priceOptions": [],
    "data": {
        "itemType": "merch"
    },
    "images": []
}


const holderItemTemplate = {
    "image": "",
    "backImage": "",
    "sizeOptions": [],
    "description": "",
    "label": "",
    "categories": [],
    "defaultColor": "",
    "colorOptions": [],
    "priceOptions": [],
    "data": {
        "itemType": "holders"
    },
    "images": []
}

const voucherItemTemplate = {
    "image": "",
    "images": [],
    "backImage": "",
    "sizeOptions": [],
    "description": "",
    "label": "",
    "categories": [],
    "defaultColor": "",
    "colorOptions": [],
    "priceOptions": [],
    "data": {
        "itemType": "voucher",
        "redeemOnly": false,
        "noForm": false
    }
}

export default function ItemFormInputs({ itemData, setItemData }) {
    const { connection } = useConnection()
    const { project } = useContext(StoreContext)
    return (
        <>
            <div className='field'>
                <label className='label'>Item Type</label>
                <select required value={itemData.data?.itemType} className='select' onChange={(event) => {
                    setItemData({ ...(event.target.value === 'merch' ? merchItemTemplate : event.target.value === 'holders' ? holderItemTemplate : voucherItemTemplate), itemId: itemData.itemId })
                }}>
                    {
                        ['Merch', 'Voucher', 'Holders'].map((x, i) => {
                            return (
                                <option value={x.toLowerCase()} key={i}>{x}</option>
                            )
                        })
                    }
                </select>
            </div>

            <div className='field half'>
                <label className='label'>Item Name</label>
                <input className='text-input' required type='text' value={itemData.label} onChange={(event) => setItemData((prev) => { return { ...prev, label: event.target.value } })} />
            </div>

            <div className='field half'>
                <label className='label'>Image URL</label>
                <input className='text-input' required type='text' value={itemData.image} onChange={(event) => setItemData((prev) => { return { ...prev, image: event.target.value } })} />
            </div>

            <div className='field'>
                <label className='label'>Description</label>
                <textarea className='textarea' required cols='50' rows='4' value={itemData.description} onChange={(event) => setItemData((prev) => { return { ...prev, description: event.target.value } })}></textarea>
            </div>

            {itemData.data?.itemType === 'holders' || itemData.data?.itemType === 'merch' ? (
                <>
                    <div className='field'>
                        <label className='label'>Size Options</label>
                        <ArrayInput array={itemData.sizeOptions} onAdd={(value) => {
                            setItemData((prev) => { return { ...prev, sizeOptions: [...prev.sizeOptions, value] } })
                        }} onRemove={(index) => {
                            setItemData((prev) => {
                                const newItemData = { ...prev };
                                newItemData.sizeOptions = newItemData.sizeOptions.slice(0, index).concat(newItemData.sizeOptions.slice(index + 1));
                                return newItemData;
                            });
                        }} />
                    </div>
                    <div className='field'>
                        <label className='label'>Color Options</label>
                        <ArrayInput array={itemData.colorOptions.map(y => y.color)} onAdd={(value) => {
                            setItemData((prev) => { return { ...prev, colorOptions: [...prev.colorOptions, { color: value }] } })
                        }} onRemove={(index) => {
                            setItemData((prev) => {
                                const newItemData = { ...prev };
                                newItemData.colorOptions = newItemData.colorOptions.slice(0, index).concat(newItemData.colorOptions.slice(index + 1));
                                return newItemData;
                            });
                        }} />
                    </div>
                    <div className='field half'>
                        <label className='label'>Domestic Shipping</label>
                        <input className='text-input' required type='number' value={itemData.data?.domesticShippingCost} onChange={(event) => setItemData((prev) => {
                            let newItemData = { ...prev }
                            _.set(newItemData, 'data.domesticShippingCost', event.target.value)
                            return newItemData
                        })} />
                    </div>
                    <div className='field half'>
                        <label className='label'>International Shipping</label>
                        <input className='text-input' required type='number' value={itemData.data?.intlShippingCost} onChange={(event) => setItemData((prev) => {
                            let newItemData = { ...prev }
                            _.set(newItemData, 'data.intlShippingCost', event.target.value)
                            return newItemData
                        })} />
                    </div>

                </>
            ) : (<>
                <div className='field'>
                    <label className='label'>NFT Reward</label>
                    <textarea className='textarea' required cols='50' rows='4' value={safeJSONStringify(itemData.data?.autoBuy?.data?.nftReward)} onChange={(event) => setItemData((prev) => {
                        let newItemData = { ...prev }
                        _.set(newItemData, 'data.autoBuy', {
                            "type": "nft",
                            "data": {
                                "nftReward": event.target.value,
                                "solReward": 0
                            }
                        })
                        return newItemData
                    })} />
                </div>

                <div className='field'>
                    <label className='label'>SOL Reward</label>
                    <input className='text-input' required type='number' value={itemData.data?.autoBuy?.data?.solReward} onChange={(event) => setItemData((prev) => {
                        let newItemData = { ...prev }
                        _.set(newItemData, 'data.autoBuy', {
                            "type": "sol",
                            "data": {
                                "nftReward": [],
                                "solReward": event.target.value
                            }
                        })

                        return newItemData
                    })} />
                </div>
            </>)}
            <div className='field'>
                <label className='label'>Price Options</label>
                <AddPriceOptionInput array={itemData.priceOptions} onAdd={async (value) => {
                    if (value.price && value.usdPrice) return enqueueSnackbar({ message: 'You cannot use both USD Price or Fixed Price together.', variant: 'error' })
                    if (value.type === 'SOL') _.set(value, 'data.symbol', 'SOL')
                    else {
                        const tokenAddress = value.data?.tokenAddress
                        const token = await getTokenInfo(tokenAddress, connection)
                        if (!token.status) {
                            return enqueueSnackbar({ message: token.message, variant: 'error' })
                        } else {
                            _.set(value, 'data.tokenAddress', tokenAddress)
                            _.set(value, 'data.symbol', token.data.tokenSymbol)
                            _.set(value, 'data.decimals', token.data.tokenDecimals)
                        }
                    }
                    setItemData((prev) => { return { ...prev, priceOptions: [...prev.priceOptions, value] } })
                }} onRemove={(index) => {
                    setItemData((prev) => {
                        const newItemData = { ...prev };
                        newItemData.priceOptions = newItemData.priceOptions.slice(0, index).concat(newItemData.priceOptions.slice(index + 1));
                        return newItemData;
                    });
                }} />
            </div>

            <div className='field half'>
                <label className='label'>Seller Wallet</label>
                <input className='text-input' required type='text' value={itemData.data?.itemFundsWallets?.[0]?.walletAddress} onChange={(event) => setItemData((prev) => {
                    let newItemData = { ...prev }
                    _.set(newItemData, 'data.itemFundsWallets[0]', {
                        percent: 100 - (Number(newItemData.data?.projectShare) || 0),
                        walletAddress: event.target.value
                    })
                    return newItemData
                })} />
            </div>
            <div className='field half'>
                <label className='label'>Seller Discord ID</label>
                <input className='text-input' required type='text' value={itemData.data?.sellerDiscordId} onChange={(event) => setItemData((prev) => {
                    let newItemData = { ...prev }
                    _.set(newItemData, 'data.sellerDiscordId', event.target.value)
                    return newItemData
                })} />
            </div>
            <div className='field half'>
                <label className='label'>Seller Channel Webhook</label>
                <input className='text-input' required type='text' value={itemData.data?.sellerWebhook} onChange={(event) => setItemData((prev) => {
                    let newItemData = { ...prev }
                    _.set(newItemData, 'data.sellerWebhook', event.target.value)
                    return newItemData
                })} />
            </div>

            <div className='field half'>
                <label className='label'>Project Share</label>
                <input className='text-input' required type='number' value={itemData.data?.projectShare || itemData.data?.itemFundsWallets?.find(z => z?.walletAddress === project.data?.storeFundsWallet)?.percent} onChange={(event) => setItemData((prev) => {
                    let value = event.target.value
                    if (isNaN(event.target.value) || Number(event.target.value) <= 0) value = ''
                    let newItemData = { ...prev }
                    if (newItemData.data?.itemFundsWallets?.length) newItemData.data.itemFundsWallets[0].percent = 100 - (Number(value) || 0)
                    _.set(newItemData, 'data.projectShare', value)
                    return newItemData
                })} />
            </div>

            <div class='field'>
                <ul class='checkboxes'>
                    <li class='checkbox'>
                        <input class='checkbox-input' id='item-form__disabled' type='checkbox' checked={itemData.data?.disabled} onChange={(event) => {
                            setItemData((prev) => {
                                let value = event.target.checked
                                let newItemData = { ...prev }
                                _.set(newItemData, 'data.disabled', value)
                                return newItemData
                            })
                        }} />
                        <label class='checkbox-label' for='item-form__disabled'>Disabled</label>
                    </li>
                </ul>
            </div>

        </>
    )
}
function safeJSONStringify(obj) {
    try {
        if (typeof (obj) === 'string') return obj
        const jsonString = JSON.stringify(obj);
        return jsonString;
    } catch (error) {
        return obj;
    }
}
