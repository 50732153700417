import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'

export default function AddPriceOptionInput({ array, onAdd, onRemove }) {
    const [value, setValue] = useState({
        type: 'SOL',
        price: '',
        usdPrice: '',
        data: {
            tokenAddress: ''
        }
    })
    const [disableAddBtn, setDisableAddBtn] = useState(false)
    return (
        <div className='flex flex-wrap array-input'>
            <div className='flex flex-row justify-center flex-wrap' style={{ marginBottom: '0.5rem', gap: '0.5rem' }}>
                {array.map((x, i) => (
                    <div key={i} className='element'>{x?.price} {x.data?.symbol} {x.usdPrice && `| $${x.usdPrice}`}<IconButton onClick={async (event) => {
                        event.preventDefault()
                        setDisableAddBtn(true)
                        await onRemove(i)
                        setDisableAddBtn(false)
                    }}><MdDelete /></IconButton></div>
                ))}
            </div>
            <div className='flex flex-wrap' style={{ width: '100%' }}>
                <div className='half'>

                    <select value={value?.type} className='select' onChange={(event) => {
                        setValue((prev) => { return { ...prev, type: event.target.value } })
                    }}>
                        {
                            ['SOL', 'SPL-TOKEN'].map((x, i) => {
                                return (
                                    <option value={x} key={i}>{x}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='half'>
                    <input className='text-input' type='text' placeholder='USD Price' value={value['usdPrice']} onChange={(event) => {
                        setValue((prev) => {
                            if (isNaN(event.target.value) || Number(event.target.value) <= 0) return { ...prev, usdPrice: '' }
                            else return { ...prev, usdPrice: Number(event.target.value) }
                        })
                    }} />
                </div>
                <div className='half'>
                    <input className='text-input' type='text' placeholder='Fixed Price' value={value['price']} onChange={(event) => {
                        setValue((prev) => {
                            if (isNaN(event.target.value) || Number(event.target.value) <= 0) return { ...prev, price: '' }
                            else return { ...prev, price: Number(event.target.value) }
                        })
                    }} />
                </div>
                {value?.type === 'SPL-TOKEN' && (
                    <div className='half'>
                        <input className='text-input' type='text' placeholder='Token Address' value={value?.data?.tokenAddress} onChange={(event) => {
                            setValue((prev) => {
                                return { ...prev, data: { tokenAddress: event.target.value } }
                            })
                        }} />
                    </div>
                )}
                <div className='half'>
                    <button className='add-element-btn' disabled={disableAddBtn} onClick={async (event) => {
                        event.preventDefault()
                        setDisableAddBtn(true)
                        await onAdd(value)
                        setDisableAddBtn(false)
                    }}>ADD</button>
                </div>
            </div>
        </div >
    )
}
