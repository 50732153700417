import React, { createContext, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import { getItems, getProject, updatePrices } from './javascript/store'
import Item from './pages/Item'
import Cart from './pages/Cart'
import Preloader from './components/utility/Preloader'
import Merch from './pages/Merch'
import Vouchers from './pages/Vouchers'
import Holders from './pages/Holders'
import Dashboard from './pages/Dashboard'
import { SignModal } from './components/utility/SignModal'

export const StoreContext = createContext({
    prices: undefined,
    categories: undefined,
    items: undefined,
    setItems: undefined,
    project: undefined,
    signModalState: undefined
})

export default function App() {
    // const isMobile = useCheckMobileScreen()
    const [prices, setPrices] = useState()
    const [categories, setCategories] = useState()
    const [project, setProject] = useState()
    const [items, setItems] = useState()
    const [displaySignModal, setDisplaySignModal] = useState(false)

    useEffect(() => {
        updatePrices(setPrices)
        getProject(setProject, setCategories)
        getItems(setItems)
    }, [])
    if (!prices || !items || !categories || !project) {
        return (
            <div className='utility__loader-container'>
                <Preloader />
            </div>
        )
    } else {

        return (
            <StoreContext.Provider value={{
                prices,
                categories,
                items,
                setItems,
                project,
                signModalState: [displaySignModal, setDisplaySignModal]
            }}>
                <SignModal displayModal={displaySignModal} setDisplayModal={setDisplaySignModal} />

                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/merch' element={<Merch />} />
                    <Route path='/vouchers' element={<Vouchers />} />
                    <Route path='/holders' element={<Holders />} />
                    <Route path='/dashboard' element={<Dashboard />} />

                    <Route path='/item/:itemId' element={<Item />} />
                    <Route path='/cart/*' element={<Cart />} />

                </Routes>
            </StoreContext.Provider>
        )
    }
}
