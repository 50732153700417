import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/store.css';
import './css/utility.css';

import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import WalletContextProvider from './components/utility/WalletContextProvider';
import App from './App';
const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <BrowserRouter>
    <WalletContextProvider>
      <SnackbarProvider
        autoHideDuration={5000}
        maxSnack={5}
        // action={key => <Button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: '0.1rem', minWidth: 'fit-content'}} onClick={() => closeSnackbar(key)}><CloseButton id={key} /></Button>}
        className={'utility__snack-bar'}
      />
      <App/>
    </WalletContextProvider>
  </BrowserRouter>
);

