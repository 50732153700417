import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import {  WalletModalProvider } from '@solana/wallet-adapter-react-ui';

import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter
} from '@solana/wallet-adapter-wallets';

import {  useMemo } from 'react';
import config from '../../config';
require('@solana/wallet-adapter-react-ui/styles.css');


const WalletContextProvider = function ({ children }) {

    const network = config.rpcURL;
    
    const endpoint = useMemo(() => (network), [network]);
    
    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolletExtensionWalletAdapter(),
            new SolletWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter()
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};


export default WalletContextProvider;
