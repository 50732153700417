import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StoreContext } from '../App'
import { getItemPrice, useCheckMobileScreen } from '../javascript/utility'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { Button, Grid, IconButton } from '@mui/material'
import { HiMinus, HiPlus } from 'react-icons/hi'
import { addToCart } from '../javascript/store'
import Navbar from '../components/utility/Navbar'
import RedeemItemModal from '../components/claim/RedeemItemModal'

export default function Item() {
    const { items } = useContext(StoreContext)
    const { itemId } = useParams()
    const isMobile = useCheckMobileScreen()
    const [item, setItem] = useState()
    useEffect(() => {
        let newItem = items.find(z => z.itemId === itemId)
        if (newItem) setItem(newItem)
        else setItem({ notFound: true })
    }, [itemId, items])
    if (!item) {
        return (
            <>
                <Navbar />
                <div className='container-padding centered-text'>
                    Loading...
                </div>
            </>
        )
    } else if (item.notFound) {
        return (

            <>
                <Navbar />
                <div className='container-padding centered-text'>
                    No Item found
                </div>

            </>
        )
    } else {
        return (
            <>
                <Navbar />
                <div className='container-padding item__wrapper' style={{ flexWrap: isMobile ? 'wrap' : 'nowrap', paddingTop: '2rem', paddingBottom: '2rem' }}>
                    <ItemImages item={item} />
                    <ItemInfo item={item} />
                </div>
            </>
        )
    }
}

const ItemInfo = ({ item }) => {
    const { prices } = useContext(StoreContext)
    const [size, setSize] = useState(item.sizeOptions[0])
    const [color, setColor] = useState(item.defaultColor)
    const [quantity, setQuantity] = useState(1)
    const [displayRedeemModal, setDisplayRedeemModal] = useState(false)

    return (<div className='flex flex-column item__info__container'>
        <RedeemItemModal item={item} size={size} color={color} quantity={quantity} displayModal={displayRedeemModal} setDisplayModal={setDisplayRedeemModal} />
        <div className='heading-text'>
            {item.label}
        </div>
        <div className='font-inter'>
            {getItemPrice(item, prices)}
        </div>
        {!item.data?.noForm ? (
            <>
                <div className='item__info__red-options'>
                    {item.colorOptions.length ? item.colorOptions.map((x, i) => {
                        return (
                            <div key={i} className='item__info__red-option'>
                                <input onChange={() => setColor(x.color)} defaultChecked={i === 0 ? true : false} id={`item__info__red-option__color__${x.color}`} name='item__info__red-option__color' type='radio' value='0' style={{ display: 'none' }} />
                                <label htmlFor={`item__info__red-option__color__${x.color}`}>{x.color}</label>
                            </div>
                        )
                    }) : (<></>)}
                </div>

                <div className='item__info__red-options'>
                    {item.sizeOptions.length ? item.sizeOptions.map((x, i) => {
                        return (
                            <div key={i} className='item__info__red-option'>
                                <input onChange={() => setSize(x)} defaultChecked={i === 0 ? true : false} id={`item__info__red-option__size__${x}`} name='item__info__red-option__size' type='radio' value='0' style={{ display: 'none' }} />
                                <label htmlFor={`item__info__red-option__size__${x}`}>{x}</label>
                            </div>
                        )
                    }) : (<></>)}
                </div>
                <div className='item__info-input__quantity font-inter'>
                    <IconButton onClick={() => {
                        if (quantity - 1) setQuantity(quantity - 1)
                        else setQuantity(1)
                    }}>
                        <HiMinus style={{ color: 'white' }} />
                    </IconButton>
                    {quantity}
                    <IconButton onClick={() => {
                        setQuantity(quantity + 1)
                    }}>
                        <HiPlus style={{ color: 'white' }} />
                    </IconButton>
                </div>
            </>
        ) : (<></>)}

        <div className='flex flex-column' style={{ gap: '0.5rem' }}>
            {!item.data.comingSoon && (
                <>
                    {!item.data?.redeemOnly ? <Button className='button__red' style={{ maxWidth: '400px' }} onClick={() => {
                        addToCart(item, color, size, quantity)
                    }}>Add to Cart</Button>
                        : <></>}
                    {!item.data.noRedeem ? (<Button className='button__yellow' style={{ maxWidth: '400px' }} onClick={() => {
                        setDisplayRedeemModal(true)
                    }}>Redeem Item</Button>) : <></>}
                </>
            )}
        </div>

        <div>
            <div style={{ fontSize: '1.5rem', paddingBottom: '1rem' }}>SODEAD ...</div>
            {item.description}
        </div>
    </div>
    )
}

const ItemImages = ({ item }) => {
    const isMobile = useCheckMobileScreen()
    const images = [item.image, item.backImage, ...(item.images || [])].filter(z => z)
    const [currentImage, setCurrentImage] = useState(0)
    if (isMobile) {
        return (<div className='flex flex-column' style={{ padding: '0.5rem 0px', maxHeight: 'fit-content', gap: '0.5rem', width: '300px', maxWidth: '80vw', textAlign: 'center' }}>
            <div className='item__image'>
                <img src={images[currentImage]} alt={item.label} style={{ maxHeight: '100%', width: '100%' }} />
            </div>
            {images.length > 1 && (<div className='flex flex-row flex-justify-center'>
                <IconButton onClick={() => {
                    if (!images[currentImage - 1]) {
                        setCurrentImage(images.length - 1)
                    } else {
                        setCurrentImage(currentImage - 1)
                    }
                }}>
                    <MdArrowBackIos style={{ color: 'white' }} />
                </IconButton>

                <IconButton onClick={() => {
                    if (!images[currentImage + 1]) {
                        setCurrentImage(0)
                    } else {
                        setCurrentImage(currentImage + 1)
                    }
                }}>
                    <MdArrowForwardIos style={{ color: 'white' }} />
                </IconButton>
            </div>)}
        </div>
        )
    } else {
        return (
            <div className='flex flex-column' style={{ width: '40%', gap: '1.5rem' }}>
                <div className='item__image'>
                    <img alt="front" src={images[0]} />
                </div>
                <Grid container spacing={{ xs: 1, md: 1, lg: 1 }} columns={{ xs: 4, sm: 6, md: 6, lg: 10 }}>

                    {images.filter((_, i) => i !== 0).map((x, i) => {
                        return (<Grid item xs={2} sm={3} md={3} lg={4} key={i}>
                            <div className='item__image'>
                                <img alt={x} src={x} />
                            </div>
                        </Grid>)
                    })}
                </Grid>
            </div>
        )
    }
}