import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { HiMinus, HiPlus } from 'react-icons/hi'
import { MdDelete } from 'react-icons/md'
import { StoreContext } from '../../App'
import { callStorageChangeEvent } from '../../javascript/utility'
import { deleteFromCart } from '../../javascript/store'

export default function CartItemsTable({ cart, tokenOption }) {
    const { items } = useContext(StoreContext)

    return (<TableContainer sx={{ backgroundColor: 'transparent' }} component={Paper}>
        <Table sx={{ minWidth: '100%' }}>
            <TableHead>
                <TableRow sx={{
                    '& .MuiTableCell-head': {
                        paddingBottom: '0.3rem',
                        color: 'white',
                        fontFamily: 'Strange dreams',
                        borderBottom: '1px solid #262626'
                    }
                }}>
                    <TableCell >Product</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="left" ></TableCell>
                    <TableCell align="left">Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {cart.map(x => {
                    return {
                        ...items.find(item => item.itemId === x.itemId),
                        ...x
                    }
                }).map((x, i) => (
                    <CartItemRow tokenOption={tokenOption} x={x} key={i} />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    )
}


const CartItemRow = ({ x, tokenOption }) => {
    const [quantity, setQuantity] = useState(x.quantity)
    const { prices, project } = useContext(StoreContext)
    useEffect(() => {
        let products = []
        if (localStorage.getItem('cart_items')) {
            products = JSON.parse(localStorage.getItem('cart_items'));
        }
        products = products.map(z => {
            if (z.itemId === x.itemId) return { ...z, quantity: quantity }
            else return z
        })
        window.localStorage.setItem('cart_items', JSON.stringify(products));
        callStorageChangeEvent()

    }, [quantity, x.itemId])

    return (<TableRow sx={{
        '& .MuiTableCell-root': {
            color: 'white',
            fontFamily: 'Inter',
            borderBottom: '1px solid #262626'
        }
    }}>
        <TableCell component="th" scope="row" >
            <div className='flex flex-row' style={{ gap: '1rem' }}>
                <div className='item-container' style={{ maxWidth: '50vw', width: '190px' }}>
                    <div className='item_container__img_container'>
                        <img src={x.image} alt={x.label} style={{width: '100%', height: 'auto'}}/>
                    </div>
                </div>
                <div>
                    <div className='font-strange' style={{ fontSize: '1.5rem', marginTop: '1.2rem', maxWidth: '300px' }}>
                        {x.label}
                    </div>
                    <div>
                        {
                            x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption) ?
                                (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice ?
                                    `${parseFloat(((x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice) / (prices[tokenOption] || project.data.storeOptions.find(z => z.tokenAddress === tokenOption).fixedUSDPrice)).toFixed(3))} ${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).data.symbol}` :
                                    `${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).price} ${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).data.symbol}`) :
                                (x.priceOptions[0].usdPrice ?
                                    `${parseFloat(((x.priceOptions[0].usdPrice) / prices[x.priceOptions[0].data.tokenAddress]).toFixed(3))} ${x.priceOptions[0].symbol}` :
                                    `${x.priceOptions[0].price} ${x.priceOptions[0].symbol}`)
                        }
                    </div>
                </div>
            </div>
        </TableCell>
        <TableCell align="left">
            <div className='item__info-input__quantity font-inter' style={{ color: 'white' }}>
                <IconButton onClick={() => {
                    if (quantity - 1) setQuantity(quantity - 1)
                    else setQuantity(1)
                }}>
                    <HiMinus style={{ color: 'white' }} />
                </IconButton>
                {quantity}
                <IconButton onClick={() => {
                    setQuantity(quantity + 1)
                }}>
                    <HiPlus style={{ color: 'white' }} />
                </IconButton>
            </div>

        </TableCell>
        <TableCell align="left">
            <IconButton onClick={() => deleteFromCart(x)} >
                <MdDelete style={{ color: 'white' }} />
            </IconButton>
        </TableCell>
        <TableCell align="left">
            {x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption) ?
                (x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice ?
                    `${parseFloat(((x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).usdPrice) / (prices[tokenOption] || project.data.storeOptions.find(z => z.tokenAddress === tokenOption).fixedUSDPrice) * quantity).toFixed(3))} ${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).data.symbol}` :
                    `${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).price * quantity} ${x.priceOptions.find(z => (tokenOption === 'So11111111111111111111111111111111111111112' && z.type === 'SOL') || z.data.tokenAddress === tokenOption).data.symbol}`) :
                (x.priceOptions[0].usdPrice ?
                    `${parseFloat(((x.priceOptions[0].usdPrice) / prices[x.priceOptions[0].data.tokenAddress] * quantity).toFixed(3))} ${x.priceOptions[0].symbol}` :
                    `${x.priceOptions[0].price * quantity} ${x.priceOptions[0].data.symbol}`)}
        </TableCell>
    </TableRow>)
}