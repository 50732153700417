import React, { useContext } from 'react'
import headerImage from './../assets/img/home/header.png'
import { Button, Grid } from '@mui/material'
import { StoreContext } from '../App'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Navbar from '../components/utility/Navbar'
import { getItemPrice } from '../javascript/utility'

export default function Home() {
  return (
    <>
      <Navbar />
      <div className='container-padding'>
        <Header />
        <Items />
      </div>
    </>
  )
}

const Header = () => {
  return (
    <div className='header-container'>
      <div className='header__image'>
        <img src={headerImage} alt="" />
      </div>
      <div className='header__content'>
        <div>
          SODEAD COLLECTION #1
        </div>
        <Link to={'/merch'} style={{ textDecoration: 'none' }}>
          <Button className='button__red'>
            Shop Now
          </Button>
        </Link>
      </div>
    </div>
  )
}

const Items = () => {
  const { items, prices } = useContext(StoreContext)
  return (
    <div className='home__categories__container'>
      {['Merch', 'Holders', 'Voucher'].map((x, i) => {
        if (items.filter(z => z.data?.itemType === x.toLowerCase() && !z.data?.disabled).length) {
          return (
            <div key={i} style={{ display: 'flex', flexFlow: 'column', gap: '1rem' }}>
              <div className='home__category__label'>
                {x}
              </div>
              <div className='home__category__items__container'>
                <Grid container spacing={{ xs: 2, md: 2, lg: 4 }} columns={{ xs: 4, sm: 12, md: 12, lg: 20 }}>
                  {items.filter(z => z.data?.itemType === x.toLowerCase() && !z.data?.disabled).slice(0, 5).map((z, j) => {
                    return (
                      <Grid item xs={2} sm={3} md={3} lg={4} key={j}>
                        <Link to={`/item/${z.itemId}`} style={{ textDecoration: 'none' }}>
                          <div className='home__category__item item-container catalog__category__item'>
                            <div className='item_container__img_container'>
                              <LazyLoadImage src={z.image} alt={z.label} />
                            </div>
                            <div className='home__category__item__content-text'>
                              <div>
                                {z.label}
                              </div>
                              {z.data?.comingSoon ? <div className='font-inter'>Coming Soon</div> : !z.data?.redeemOnly ? (
                                <div className='font-inter'>
                                  {getItemPrice(z, prices)}
                                </div>
                              ) : <></>}
                            </div>
                          </div>
                        </Link>
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
            </div>
          )
        } else return true
      })}
    </div >
  )
}