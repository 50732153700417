import { useWallet } from "@solana/wallet-adapter-react"
import { useContext, useState } from "react"
import { StoreContext } from "../../App"
import { createItem } from "../../javascript/dashboard"
import ItemFormInputs, { merchItemTemplate } from "./ItemFormInputs"


const CreateItemForm = ({ displayModal, setDisplayModal }) => {
    const [itemData, setItemData] = useState(merchItemTemplate)
    const [disableBtn, setDisableBtn] = useState(false)
    const { publicKey } = useWallet()
    const { project, setItems, signModalState } = useContext(StoreContext)
    const [, setDisplaySignModal] = signModalState

    return itemData && displayModal ? (
        <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
            <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
            <div className='modal-container'>
                <div className='modal'>
                    <form className='utility__form font-inter'>
                        <div className='heading-text font-strange' style={{ margin: '2rem 0px' }}>
                            Create item
                        </div>

                        <ItemFormInputs itemData={itemData} setItemData={setItemData} />

                        <div className='field half'></div>
                        <div className='field half'></div>

                        <div className='field half'>
                            <button className='button green-button' disabled={disableBtn} onClick={() => createItem(project, itemData, setDisableBtn, publicKey, project.id, setItems, setDisplayModal, setDisplaySignModal)}>Create</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    ) : (<></>)

}

export default CreateItemForm