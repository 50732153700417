import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js"
import config from "../config"
import { getAssociatedTokenAddress } from "@solana/spl-token"
import { getAccount } from "@solana/spl-token"
import { createAssociatedTokenAccountInstruction } from "@solana/spl-token"
import { createTransferInstruction } from "@solana/spl-token"
import { enqueueSnackbar } from "notistack"
import { callStorageChangeEvent } from "./utility"

export const updatePrices = async (setPrices) => {
    try {
        const req = await fetch(`${config.apiURL}/get-store-token-prices`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        })
        const reqJson = await req.json()
        if (reqJson?.status) setPrices(reqJson.data)
        else setPrices()
    } catch (err) {
        console.log(err)
        setPrices()
    }
}

export const getProject = async (setProject, setCategories) => {
    try {
        const req = await fetch(`${config.apiURL}/get-project`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: 'sodead'
            })
        })
        const reqJson = await req.json()
        if (reqJson) {
            setProject(reqJson)
            setCategories(reqJson.data.storeCategories)
        }
    } catch (err) {
        console.log(err)
        setCategories()
    }
}


export const getItems = async (setItems) => {
    try {
        const req = await fetch(`${config.apiURL}/get-store-items`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: 'sodead'
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setItems(reqJson.data)
        else setItems()
    } catch (err) {
        console.log(err)
        setItems()
    }
}



export const placeOrder = async (publicKey, project, infos, priceOption, item, setDisableBtn, connection, signTransaction, setDisplayModal, cart) => {
    try {
        setDisableBtn(true)
        const costReq = await fetch(`${config.apiURL}/get-store-price-quote`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: project.id,
                walletAddress: publicKey,
                ...infos,
                optionId: !cart ? priceOption.optionId : priceOption.tokenAddress,
                itemId: item.itemId,
                cart: cart ? item : undefined
            })
        })
        const costReqJson = await costReq.json()
        let signature;
        let tx = new Transaction()
        if (costReqJson?.status) {
            const payments = costReqJson.data.price
            const discountChosen = costReqJson?.data?.discount
            for (const payment of payments) {
                if (payment.type === 'SOL') {
                    let amount = payment.amount
                    if (discountChosen) amount = Math.ceil((100 - discountChosen.discount) / 100 * amount)
                    tx.add(SystemProgram.transfer({
                        fromPubkey: new PublicKey(publicKey),
                        toPubkey: new PublicKey(payment.toWallet),
                        lamports: amount
                    }))
                }
                if (payment.type === 'SPL-TOKEN') {
                    let tokenPayment = payment.amount
                    if (discountChosen) tokenPayment = Math.ceil((100 - discountChosen.discount) / 100 * tokenPayment)

                    const mintToken = new PublicKey(payment.data.tokenAddress)
                    const recipientAddress = new PublicKey(payment.toWallet)

                    const associatedTokenFrom = await getAssociatedTokenAddress(
                        mintToken,
                        publicKey
                    );
                    const fromAccount = await getAccount(connection, associatedTokenFrom);
                    const associatedTokenTo = await getAssociatedTokenAddress(
                        mintToken,
                        recipientAddress
                    );

                    if (!(await connection.getAccountInfo(associatedTokenTo))) {
                        const instruction = createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            recipientAddress,
                            mintToken
                        )
                        tx.add(instruction)
                    }
                    const instruction2 = createTransferInstruction(
                        fromAccount.address,
                        associatedTokenTo,
                        publicKey,
                        tokenPayment
                    )
                    tx.add(instruction2)
                }
            }
            let blockhash = await connection.getLatestBlockhash()
            tx.feePayer = publicKey
            tx.recentBlockhash = blockhash.blockhash
            const signed = await signTransaction(tx)
            signature = await connection.sendRawTransaction(signed.serialize())
            await connection.confirmTransaction(signature, 'processed')

            async function sendReq() {
                const req = await fetch(`${config.apiURL}/store-place-order`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        projectId: project.id,
                        walletAddress: publicKey,
                        ...infos,
                        optionId: !cart ? priceOption.optionId : priceOption.tokenAddress,
                        itemId: item.itemId,
                        cart: cart ? item : undefined,
                        signature: signature,
                        quoteId: costReqJson.data.quoteId
                    })
                })
                const reqJson = await req.json()
                if (!reqJson.status && reqJson.message === 'Transaction not found') {
                    const newReqJson = await sendReq()
                    return newReqJson
                }
                return reqJson
            }
            const reqJson = await sendReq()
            if (reqJson?.status) {
                setDisplayModal(false)
                if (cart) {
                    localStorage.setItem('cart_items', '[]')
                    window.location.replace(`http://${window.location.host}${window.location.pathname.replace('checkout', '')}`)
                }
                enqueueSnackbar({ message: infos.redeem ? 'Item Redeemed' : reqJson.message, variant: 'success' })
                setDisableBtn(false)
            } else {
                enqueueSnackbar({ message: reqJson.message, variant: 'error' })
                setDisableBtn(false)
            }
        } else {
            enqueueSnackbar({ message: costReqJson.message, variant: 'error' })
            setDisableBtn(false)
            return
        }
    } catch (err) {
        console.log(err)
        enqueueSnackbar({ message: err?.toString() === 'TokenAccountNotFoundError' ? 'You dont have enough tokens to pay' : err.message || err.msg || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}


export const addToCart = (item, color, size, quantity) => {
    try {
        let products = []
        if (localStorage.getItem('cart_items')) {
            products = JSON.parse(localStorage.getItem('cart_items'));
        }
        if (products.find(z => z.itemId === item.itemId)) {
            return enqueueSnackbar({ message: `This Item has already been added to your cart`, variant: 'error' })
        } else {
            products.push({
                itemId: item.itemId,
                color: color,
                size: size,
                quantity: quantity
            });
        }
        window.localStorage.setItem('cart_items', JSON.stringify(products));
        callStorageChangeEvent()
        enqueueSnackbar({ message: `Successfully added item to your cart!`, variant: 'success' })
    } catch (err) {
        enqueueSnackbar({ message: err.message || err.msg || `An unknown error occured`, variant: 'error' })
    }
}

export const deleteFromCart = (item) => {
    try {
        let products = []
        if (localStorage.getItem('cart_items')) {
            products = JSON.parse(localStorage.getItem('cart_items'));
        }
        products = products.filter(z => z.itemId !== item.itemId)
        window.localStorage.setItem('cart_items', JSON.stringify(products));
        callStorageChangeEvent()
    } catch (err) {
        enqueueSnackbar({ message: err.message || err.msg || `An unknown error occured`, variant: 'error' })
    }
}