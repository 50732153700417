import React, { useContext, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { StoreContext } from '../App'
import { Grid, Popover, TextField } from '@mui/material'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Navbar from '../components/utility/Navbar'
import { getItemPrice } from '../javascript/utility'
export default function Merch() {
    const { prices, items } = useContext(StoreContext)
    const [filteredItems, setFilteredItems] = useState(items.filter(z => z.data?.itemType === 'merch' && !z.data?.disabled))
    const [fromAmount, setFromAmount] = useState('')
    const [toAmount, setToAmount] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        setFilteredItems(items.filter(z => {
            const price = z.priceOptions.find(z => z.type === 'SOL') ? (z.priceOptions.find(z => z.type === 'SOL').usdPrice ? parseFloat(((z.priceOptions.find(z => z.type === 'SOL').usdPrice) / prices['So11111111111111111111111111111111111111112']).toFixed(3)) : z.priceOptions.find(z => z.type === 'SOL').price) : (z.priceOptions[0].usdPrice ? parseFloat(((z.priceOptions[0].usdPrice) / prices[z.priceOptions[0].data.tokenAddress]).toFixed(3)) : z.priceOptions[0].price)
            return z.data?.itemType === 'merch' && price >= (fromAmount || 0) && price <= (toAmount || 10000000) && !z.data?.disabled
        }))
    }, [fromAmount, toAmount, items, prices])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Navbar />
            <div className='container-padding'>
                <div className='heading-text font-strange' style={{ margin: '2rem 0px 0px 0px' }}>
                Merchandising
                </div>
                <div style={{ display: 'flex', color: 'white', padding: '1.5rem 0px', margin: '1rem 0px 0px 0px' }} className='font-inter'>
                    <div style={{ marginRight: '1.3rem' }}>
                        Filter:
                    </div>
                    <div onClick={handleClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        Price <MdKeyboardArrowDown />
                    </div>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiPopover-paper': {
                                borderRadius: '20px'
                            }
                        }}
                    >
                        <div style={{ background: '#262626', borderRadius: '2px', padding: '2rem 1.2rem', display: 'flex', justifyContent: 'space-around', flexFlow: 'row wrap', gap: '1rem' }}>
                            <div style={{ background: '#262626', cursor: 'pointer', textDecoration: 'underline', color: 'white', width: '100%' }} onClick={() => { setFromAmount(''); setToAmount('') }}>
                                RESET
                            </div>

                            <TextField
                                value={fromAmount}
                                onChange={(event) => {
                                    let number = Number(event.target.value)
                                    setFromAmount(number)
                                }}
                                size="small"
                                type='number'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white',
                                    },
                                    '&:hover .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        color: 'white'
                                    },
                                }} style={{
                                    color: 'white',
                                    width: '120px',
                                    margin: '1.2rem 0px 0px 0px',
                                    maxWidth: '90%'
                                }} label={<div className='font-strange' style={{ color: 'white' }}>FROM</div>} className='font-strange' />


                            <TextField
                                value={toAmount}
                                onChange={(event) => {
                                    let number = Number(event.target.value)
                                    console.log(number)
                                    setToAmount(number)
                                }}
                                size="small"
                                type='number'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white',
                                    },
                                    '&:hover .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        color: 'white'
                                    }
                                }} style={{
                                    margin: '1.2rem 0px 0px 0px',
                                    color: 'white',
                                    width: '120px',
                                    maxWidth: '90%'
                                }} label={<div className='font-strange' style={{ color: 'white' }}>TO</div>} className='font-strange' />

                        </div>
                    </Popover>
                </div>
                <div className='home__category__items__container' style={{ justifyContent: 'center' }}>
                    {!filteredItems.length ? (<div style={{ color: 'white', fontSize: '2rem', textAlign: 'center' }}>
                        No Item found
                    </div>) : (<Grid container spacing={{ xs: 2, md: 2, lg: 4 }} columns={{ xs: 4, sm: 12, md: 12, lg: 20 }}>

                        {filteredItems.map((z, j) => {
                            return (
                                <Grid item xs={2} sm={3} md={3} lg={4} key={j}>
                                    <Link to={`/item/${z.itemId}`} style={{ textDecoration: 'none' }}>
                                        <div className='home__category__item item-container catalog__category__item'>
                                            <div className='item_container__img_container'>
                                                <LazyLoadImage src={z.image} alt={z.label} />

                                            </div>
                                            <div className='home__category__item__content-text'>
                                                <div style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    width: '80%',
                                                    margin: 'auto'
                                                }}>
                                                    {z.label}
                                                </div>
                                                <div className='font-inter'>
                                                    {getItemPrice(z, prices)}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Grid>
                            )
                        })}
                    </Grid>)
                    }
                </div>
            </div>
        </>
    )
}
