import { Metaplex } from "@metaplex-foundation/js";
import { PublicKey, Transaction, TransactionInstruction } from "@solana/web3.js";
import { useEffect, useState } from "react";
import config from "../config";
import { enqueueSnackbar } from "notistack";
import base58 from "bs58";

export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}


export const getItemPrice = (item, prices) => {
    let itemPrice = ''
    if (item.data?.comingSoon) itemPrice = 'Coming Soon'
    if (item.data.redeemOnly) {
        itemPrice = '1 Voucher'
    } else {
        if (item.priceOptions.find(item => item.type === 'SPL-TOKEN' && item.data?.tokenAddress === '5tdfToRGBGRqbPaBq8TAtDncgy7ejM26TwRfcmVaNpqy')) {
            if (item.priceOptions.find(item => item.type === 'SPL-TOKEN' && item.data?.tokenAddress === '5tdfToRGBGRqbPaBq8TAtDncgy7ejM26TwRfcmVaNpqy').usdPrice) {
                itemPrice = `${parseFloat(((item.priceOptions.find(item => item.type === 'SPL-TOKEN' && item.data?.tokenAddress === '5tdfToRGBGRqbPaBq8TAtDncgy7ejM26TwRfcmVaNpqy').usdPrice) / prices['5tdfToRGBGRqbPaBq8TAtDncgy7ejM26TwRfcmVaNpqy']).toFixed(3))} $VAMP`
            } else {
                itemPrice = `${item.priceOptions.find(item => item.type === 'SPL-TOKEN' && item.data?.tokenAddress === '5tdfToRGBGRqbPaBq8TAtDncgy7ejM26TwRfcmVaNpqy').price} $VAMP`
            }
        } else if (item.priceOptions[0]) {
            if (item.priceOptions[0].usdPrice) {
                console.log(item.priceOptions[0])
                itemPrice = `${parseFloat(((item.priceOptions[0].usdPrice) / prices[item.priceOptions[0].data?.tokenAddress || 'So11111111111111111111111111111111111111112']).toFixed(3))} ${item.priceOptions[0].data?.symbol}`
            } else {
                itemPrice = `${item.priceOptions[0].price} ${item.priceOptions[0]?.data?.symbol}`
            }
        }
    }
    return itemPrice
}

export const getTokenInfo = async (tokenAddress, connection) => {
    try {
        const mint = new PublicKey(tokenAddress);
        const tokenInfo = await connection.getTokenLargestAccounts(mint, 'finalized');
        if (tokenInfo && tokenInfo.value.length > 0) {
            const metaplex = new Metaplex(connection)
            let tokenMetadata;
            try {
                let tokenData = await metaplex.nfts().findByToken({ token: tokenInfo.value[tokenInfo.value.length-1].address });
                tokenMetadata = await loadSplTokenMetadata(tokenData)
                if (tokenData.jsonLoaded) tokenMetadata = tokenData.json
            } catch (e) {
                console.log(e)
            }
            if (!tokenMetadata) tokenMetadata = { image: '', name: 'SPL-TOKEN', symbol: `${tokenAddress.slice(0, 3)}...${tokenAddress.slice(-3)}` }
            return {
                status: true,
                message: undefined,
                data: {
                    tokenName: tokenMetadata.name,
                    tokenSymbol: tokenMetadata.symbol,
                    tokenDecimals: Math.pow(10, tokenInfo.value[0].decimals),
                }
            }
        } else {
            return {
                status: false,
                message: 'No token account found for the given address.',
                data: {
                }
            }
        }
    } catch (error) {
        return {
            status: false,
            message: `ERROR: ${error.message || error.msg || ''}`,
            data: {
            }
        }
    }
}

export const loadSplTokenMetadata = async (tokenMetadata) => {
    let response;
    for (var i = 0; i <= 5; i++) {
        try {
            const res = await fetch(tokenMetadata.uri)
            const tokenUriData = await res.json()
            if (tokenUriData) {
                response = tokenUriData
                break
            }
        } catch (err) {
            continue
        }
    }
    return response
}


export const callStorageChangeEvent = () => {
    const newValue = localStorage.getItem('cart_items');
    const customEvent = new CustomEvent('cartItemsChange', { detail: newValue });
    window.dispatchEvent(customEvent);
};

export const verifyWallet = async (signMessage, setDisplayModal, walletAddress, projectId, ledger, sendTransaction, connection, setDisableSignBtn) => {
    try {
        setDisableSignBtn(true)
        const nonceReq = await fetch(`${config.apiURL}/get-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId
            })
        })
        const nonce = await nonceReq.json()
        if (nonce.error) return enqueueSnackbar({ message: nonce.error, variant: 'error' })
        else {
            let signature;
            if (!ledger) {
                signature = await signMessage(new TextEncoder().encode(`${nonce.nonce}`))
                signature = base58.encode(signature)
            } else {
                let tx = new Transaction();
                await tx.add(
                    new TransactionInstruction({
                        keys: [{ pubkey: walletAddress, isSigner: true, isWritable: true }],
                        data: Buffer.from(nonce.nonce, "utf-8"),
                        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                    })
                )
                const blockHash = await connection.getLatestBlockhash();
                tx.feePayer = walletAddress;
                tx.recentBlockhash = blockHash.blockhash;

                signature = await sendTransaction(tx, connection)
                await connection.confirmTransaction({
                    blockhash: blockHash.blockhash,
                    lastValidBlockHeight: blockHash.lastValidBlockHeight,
                    signature
                });
                let checked = false
                for (var i = 0; i <= 10; i++) {
                    const response = await checkVerifiedWallet(walletAddress, projectId, nonce.id, signature)
                    if (response.nonce) {
                        await sleep(10000)
                        continue
                    } if (response.error) {
                        await sleep(10000)
                        continue
                    } else {
                        checked = true
                        break;
                    }
                }
                if (!checked) {
                    setDisableSignBtn(false)
                    return enqueueSnackbar({ message: `Couldn't verify your wallet`, variant: 'error' })
                }
            }
            if (!signature) {
                setDisableSignBtn(false)
                return enqueueSnackbar({ message: `An error occured while signing`, variant: 'error' })
            }
            else {
                localStorage.setItem('nonceSignature', signature)
                localStorage.setItem('nonceId', nonce.id)
                localStorage.setItem('nonceExpiry', Date.now() + 7 * 24 * 60 * 60 * 1000)
                setDisplayModal(false)
                setDisableSignBtn(false)
                enqueueSnackbar({ message: `Successfully signed in`, variant: 'success' })
            }
        }
    } catch (err) {
        console.log(err)
        setDisableSignBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || 'UNKNOWN'}`, variant: 'error' })
    }
}

export const checkVerifiedWallet = async (walletAddress, projectId, nonceId, signature) => {
    try {
        const nonceReq = await fetch(`${config.apiURL}/verify-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId,
                nonceId: nonceId,
                signature: signature
            })
        })
        const nonce = await nonceReq.json()
        return nonce
    } catch (err) {
        console.log(err)
        return { error: 'An unknown error occured' }
    }
}

const sleep = async (time) => {
    return await new Promise(resolve => setTimeout(resolve, time))
}