import React, { useState } from 'react';
import { useCheckMobileScreen } from './../../javascript/utility.js'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { Link, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi'
import { motion } from 'framer-motion'
const Navbar = () => {
    const isMobile = useCheckMobileScreen()

    return (
        <>
            {isMobile ? <MobileMenu /> : <DesktopMenu />}
        </>
    );
};

export default Navbar;

const DesktopMenu = () => {
    const { pathname } = useLocation()
    return (
        <div className="container">
            <div className='navbar'>
                <Link to={'/'}>
                    <img
                        src="https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407788beffc5b04ecfed655_SD_LOGO2.png"
                        alt=""
                        className="navbar-logo"
                    />
                </Link>
                <div className='nav-links'>
                    <div className='nav-links-1-2'>
                        {/* <Link to={'/'} className={`nav-link ${pathname === '/' ? 'nav-link-selected' : ''}`}>
                            Home
                        </Link> */}
                        <Link to={'/merch'} className={`nav-link ${pathname === '/merch' ? 'nav-link-selected' : ''}`} >
                            Merchandising
                        </Link>

                        <Link to={'/vouchers'} className={`nav-link ${pathname === '/vouchers' ? 'nav-link-selected' : ''}`} >
                            Vouchers
                        </Link>

                        <Link to={'/holders'} className={`nav-link ${pathname === '/holders' ? 'nav-link-selected' : ''}`} >
                            Holders Products
                        </Link>

                        <Link to={'https://discord.gg/qhektyNYVg'} className={`nav-link ${pathname === '/contact' ? 'nav-link-selected' : ''}`}>Contact</Link>
                    </div>
                    <div className='nav-links-1-2'>
                        <Link to={'/cart'}>
                            <HiOutlineShoppingBag className='nav-link' style={{ fontSize: '1.5rem' }} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

}


const MobileMenu = () => {
    const [isOpen, setOpen] = useState(false);
    const { pathname } = useLocation()
    const toggleOpen = () => setOpen(!isOpen)
    const variants = {
        open: {
            opacity: 1,
            height: 'fit-content',
            y: '-5px',
        },
        closed: {
            opacity: 0,
            height: '0px',
            y: "-100%"
        }
    }

    return (
        <div style={{ position: 'sticky', width: '100%', zIndex: 1 }}>
            <div className="navbar" style={{ padding: '1rem', height: 'fit-content', background: '#070707', zIndex: 100, position: 'relative' }}>
                <img
                    src="https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407788beffc5b04ecfed655_SD_LOGO2.png"
                    alt=""
                    className="navbar-logo"
                />
                <div onClick={toggleOpen}>
                    <GiHamburgerMenu style={{ color: 'white', fontSize: '2rem' }} />
                </div>
            </div>
            <div
                style={{ zIndex: 99, position: 'absolute', width: '100%', background: 'rgb(7, 7, 7)' }}
            >
                <motion.div
                    animate={isOpen ? "open" : "closed"}
                    variants={variants}
                    style={{ overflow: 'hidden' }}

                >
                    <div className='nav-links' style={{ flexFlow: 'column', alignItems: 'center', paddingLeft: '0px' }}>
                        <div className='nav-links-1-2' style={{ justifyContent: 'center', flexFlow: 'row wrap' }}>
                            {/* <Link to={'/'} className={`nav-link ${pathname === '/' ? 'nav-link-selected' : ''}`}>
                                Home
                            </Link> */}
                            <Link to={'/merch'} className={`nav-link ${pathname === '/merch' ? 'nav-link-selected' : ''}`} >
                                Merchandising
                            </Link>

                            <Link to={'/vouchers'} className={`nav-link ${pathname === '/vouchers' ? 'nav-link-selected' : ''}`} >
                                Vouchers
                            </Link>

                            <Link to={'/holders'} className={`nav-link ${pathname === '/holders' ? 'nav-link-selected' : ''}`} >
                                Holders Products
                            </Link>

                            <Link to={'/contact'} className={`nav-link ${pathname === '/contact' ? 'nav-link-selected' : ''}`}>Contact</Link>
                        </div>
                        <div className='nav-links-1-2' style={{ justifyContent: 'center', flexFlow: 'row wrap' }}>
                            <Link to={'/cart'}>
                                <HiOutlineShoppingBag className='nav-link' style={{ fontSize: '1.5rem' }} />
                            </Link>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};


