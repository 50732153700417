import React, { useContext, useState } from 'react'
import { useCheckMobileScreen } from '../../javascript/utility'
import { Box, Button, CircularProgress, Input } from '@mui/material'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { placeOrder } from '../../javascript/store'
import { StoreContext } from '../../App'

export default function RedeemItemModal({ displayModal, setDisplayModal, size, color, quantity, item }) {
  const { project } = useContext(StoreContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  const [country, setCountry] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const { publicKey, signTransaction } = useWallet()
  const { connection } = useConnection()
  const isMobile = useCheckMobileScreen()
  if (displayModal) {
    return (
      <div className='modal-wrapper'>
        <div className='modal-bg' onClick={() => setDisplayModal()}></div>
        <div className='modal-container'>
          <div className='modal' style={{ padding: '1rem', maxWidth: isMobile ? '95vw' : '80vw' }}>
            <div className='' style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexFlow: 'row', justifyContent: 'center' }}>
              <div className='flex flex-column flex-align-center' style={{ width: '500px', maxWidth: isMobile ? '90vw' : '40vw', padding: '1.3rem 0px' }}>
                <img
                  src="https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407788beffc5b04ecfed655_SD_LOGO2.png"
                  alt=""
                  style={{ height: '4rem' }}
                  className="navbar-logo"
                />
                <form>
                  <Box sx={{
                    color: 'white',
                    width: '100%',
                    display: 'flex',
                    flexFlow: 'column',
                    margin: '2rem 0px',
                    gap: '0.5rem',
                    '& .MuiInputBase-root': {
                      background: 'white',
                      width: '100%',
                      borderRadius: '5px'
                    },
                    '& .MuiInputBase-input': {
                      paddingLeft: '1rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem'
                    },
                    '& .button__red': {
                      fontFamily: 'Inter !important',
                      marginLeft: 'auto',
                      marginTop: '1rem',
                      width: 'fit-content'
                    }
                  }}>
                    {!item.data.noForm ? (<>
                      <div className='font-inter' style={{ fontWeight: '600' }}>
                        Contact
                      </div>
                      <Input type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />

                      <div className='font-inter' style={{ fontWeight: '600', marginTop: '1rem' }}>
                        Shipping address
                      </div>
                      <Input placeholder="Country" value={country} onChange={(event) => setCountry(event.target.value)} />
                      <div className='flex flex-row' style={{ gap: '0.5rem' }}>
                        <Input placeholder="First name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                        <Input placeholder="Last name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                      </div>
                      <Input placeholder="Address" value={address} onChange={(event) => setAddress(event.target.value)} />
                      <div className='flex flex-row' style={{ gap: '0.5rem' }}>
                        <Input placeholder="City" value={city} onChange={(event) => setCity(event.target.value)} />
                        <Input placeholder="State" value={state} onChange={(event) => setState(event.target.value)} />
                        <Input placeholder="Zip Code" value={zip} onChange={(event) => setZip(event.target.value)} />
                      </div>
                    </>) : (<></>)}
                    {publicKey ? (<Button disabled={disableBtn} className='button__red' onClick={() => placeOrder(publicKey, project,
                      { firstName, secondName: lastName, address: { address: `${address}, ${city}, ${state}`, zip: zip, country }, quantity, email, size, color, redeem: true },
                      item.priceOptions.find(z => z.data.redeemOption), item, setDisableBtn, connection, signTransaction, setDisplayModal)}>
                        {disableBtn ? <CircularProgress style={{width: '1rem', height: '1rem', marginRight: '0.5rem'}}/> : <></>}
                        PAY NOW</Button>) : (<WalletMultiButton className='button__red' />)}
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
