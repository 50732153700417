import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { MdDelete } from 'react-icons/md'

export default function ArrayInput({ array, onAdd, onRemove }) {
    const [value, setValue] = useState('')
    return (
        <div className='flex flex-wrap array-input'>
            <div className='flex flex-row justify-center flex-wrap' style={{ marginBottom: '0.5rem', gap: '0.5rem' }}>
                {array.map((x, i) => (
                    <div key={i} className='element'>{x} <IconButton onClick={(event) => {
                        event.preventDefault()
                        onRemove(i)
                    }}><MdDelete /></IconButton></div>
                ))}
            </div>
            <div className='flex flex-wrap' style={{ width: '100%' }}>
                <div className='half'>
                    <input className='text-input' type='text' value={value} onChange={(event) => setValue(event.target.value)}></input>
                </div>
                <div className='half'>
                    <button className='add-element-btn' onClick={(event) => {
                        event.preventDefault()
                        onAdd(value)
                    }}>ADD</button>
                </div>
            </div>
        </div>
    )
}




